import React, { useCallback } from 'react'
import copy from 'copy-to-clipboard';
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

export default function Hero() {

    const handleClickCopy = () => {
        copy('0x54cD329FC055ED57d657c3128e16091881A1Bcdf');
    }

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);


    return (
        <>
            <header className="page-header wow fadeInUp mb-5" data-wow-delay="0.5s" style={{ background: "00c5ff", visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp', textAlign: "center" }}>
                <video muted autoPlay playsInline loop id="myVideo" className='video'>
                    <source src="./assets/img/hero.mp4" type="video/mp4" />
                </video>
                {/* <Particles className='particles' id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: "#00c5ff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce",
                                },
                                random: false,
                                speed: 6,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 5 },
                            },

                        },
                        detectRetina: true,
                    }}
                /> */}
                <div className="container" >

                    <h2 style={{ color: "#00c5ff" }}>Grow Today</h2>
                    <br />
                    <div className="bosluk3" />
                    <p className='text-center hero-p' >With Onmax - a decentralized, secure <br /> and anonymous platform.</p>
                    <p className='text-center text-break' style={{ fontSize: "20px" }}> <a href="https://bscscan.com/token/0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A" target="_blank" rel="noopener noreferrer">0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A</a> &nbsp; <span onClick={handleClickCopy}><i className='fa fa-copy' /></span></p>

                    <a href="https://pancakeswap.finance/swap?inputCurrency=0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A&outputCurrency=0x55d398326f99059fF775485246999027B3197955" target="_blank" className='btn btn-outline-primary mx-3 py-3 px-4' style={{ fontSize: "20px", textAlign: "center", backdropFilter: "blur(10px)" }}>Buy Onmax</a>
                    <a href="https://onmax.gitbook.io/whitepaper/" target='_blank' className='btn btn-outline-primary mx-3 py-3 px-4' style={{ fontSize: "20px", textAlign: "center", backdropFilter: "blur(10px)" }}>Whitepaper</a>

                </div>
            </header>
        </>
    )
}
