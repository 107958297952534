import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
// import { injected, chainData } from "./connector/Connecter";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import Web3 from "web3";

export const OnmaxContext = createContext();

function Onmaxcontext() {
  var { active, account, activate, deactivate, error, library } =
    useWeb3React();
  // account = '0x71b5167bf1887f53d2f9dd79ac35f16496b44387'
  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [rate, setrate] = useState(0);
  const [web3, setWeb3] = useState(null);
  const [total_sell, settotal_sell] = useState(0);
  const [tsellper, settsellper] = useState(0);
  const [dailyremaining, setdailyremaining] = useState(0);
  const [hideMain, sethideMain] = useState(false);

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  // const connectInjected = async () => {
  //   try {
  //     await activate(injected);
  //   } catch (ex) {
  //     console.log(ex);
  //   }
  // };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };
  // const addchain = async () => {
  //   toastError("Connect wtih Polygon");
  //   await window.ethereum.request({
  //     method: "wallet_addEthereumChain",
  //     params: chainData,
  //   });
  // };
  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  async function disconnect() {
    try {
      deactivate();
      localStorage.setItem("isconnected", false);
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  // const connectMetamask = async () => {
  //   try {
  //     await activate(injected);

  //     localStorage.setItem("isconnected", true);
  //   } catch (error) {
  //     alert(error);
  //   }
  // };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyaddress",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };
  const getuserDB = async () => {
    var data = await getudata(account);
    if (data.data.error) {
      return "";
    }
    setuserdata(data.data.data[0]);
  };

  useEffect(() => {
    // if (localStorage.getItem("isconnected") === "true") {
    //   connectMetamask();
    // }
    getSetting();
  }, []);

  useEffect(() => {
    setWeb3(new Web3("https://bsc-pokt.nodies.app"));
    // setWeb3(new Web3(process.env.REACT_APP_RPC));
    // console.log("dasdas", new Web3(process.env.REACT_APP_RPC));
    // setWeb3(new Web3("https://bsc-dataseed.binance.org"));
  }, []);
  // useEffect(() => {
  //   if (error instanceof UnsupportedChainIdError) {
  //     console.log("error", error);
  //     addchain();
  //   }
  // }, [error]);
  const getsmcs = async () => {
    // console.log("account ", account);
    if (!web3 || !settings.presell) {
      return;
    }

    let maincontract = new web3.eth.Contract(
      JSON.parse(settings.contract_abi),
      settings.contract
    );
    if (account && settings.usdt) {
      let usdtcontract = new web3.eth.Contract(
        JSON.parse(settings.usdt_abi),
        settings.usdt
      );
      var balancea = await usdtcontract.methods.balanceOf(account).call();
      // console.log("resdasasasasd",balancea,web3.utils.fromWei(balancea, "MWei"));
      setBalance(Number(web3.utils.fromWei(balancea, "MWei")))
    } 
    var getrate = await maincontract.methods
    .tokenrate()
    // .findday('1705167900')
    .call();
    // var tsell = 0;
    // var daysale = 0;
    // for (let i = 0; i <= Number(getrate[0]); i++) {
    //   daysale = await maincontract.methods.daysale(i).call();
    //   tsell = Number(tsell) + Number(web3.utils.fromWei(daysale, "ether"));
    // }
    // var per = (tsell * 100) / 2500000;
    // var remain = await maincontract.methods.dayremaining(getrate[0]).call();
    // setdailyremaining(Number(web3.utils.fromWei(remain, "ether")));
    // settsellper(per);
    // settotal_sell(Number(tsell));
    setrate(Number(web3.utils.fromWei(getrate, "ether")).toFixed(4));
    
    
  };
  useEffect(() => {
    getsmcs();
  }, [account, web3, settings]);

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <OnmaxContext.Provider
        value={{
          settings: settings,
          library,
          balance,
          userdata,
          getuserDB,
          total_sell,
          active,
          dailyremaining,
          web3,
          tsellper,
          account: account ? account.toLowerCase() : account,
          // connectInjected,
          disconnect,
          // connectMetamask,
          formatAddress,
          toastError,
          toastSuccess,
          toastInfo,
          getsmcs,
          rate,
          copyaddress,hideMain, sethideMain
        }}
      >
        <App />
      </OnmaxContext.Provider>
    </>
  );
}

export default Onmaxcontext;
