import React from 'react'

export default function About() {
    return (
        <>
            <section className="section" id='About'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="bosluk333" />
                            <h2 className="h2-baslik-anasayfa-ozel wow fadeInLeft" data-wow-delay="0.4s"> <strong> Introducing ONMAX World's Innovative Solution
                            </strong></h2>
                            <div className="bosluk333" />
                            <div className="bosluk9" />
                            <p className="paragraf wow fadeInLeft" data-wow-delay="0.5s">
                                At ONMAX , we boast a team of seasoned developers and blockchain experts, fully committed to shaping an inclusive and decentralized digital future where individuals retain complete control over their digital assets and identities.
                            </p>
                            <p className="paragraf wow fadeInLeft" data-wow-delay="0.5s">
                                We are excited to introduce our innovative Affiliate,  staking platform and AI Based NFT on the Binance Smart Chain (BEP20), which presents users with a unique opportunity to earn rewards while maintaining the utmost security and decentralization. In this presentation, we will delve into the Future advantages of NFT staking on Binance Smart Chain (BEP20)  and elucidate the inner workings of our platform.
                            </p>
                            <div className="bosluk333" />
                            <div className="bosluk333" />
                            <div className="bosluk1" />
                            <br />
                            <a href=" https://www.youtube.com/watch?v=kvZt7t-FmXQ" target='_blank' className='btn btn-outline-primary py-3 px-4' style={{ fontSize: "20px", textAlign: "center" }}>Watch Now</a>
                        </div>
                        {/*Banner Image Alanı*/}
                        <div className="col-lg-7 col-md-12 col-sm-12 wow fade">
                            <div className="hero1 wow fadeInRight text-right" data-wow-delay="0.6s">
                                <img src="./assets/img/innovative.gif" alt=""/>
                                {/* <video muted
                                autoPlay
                                loop id="myVideo" width={600}><source src="./assets/img/about.mp4" type="video/mp4" /></video> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
