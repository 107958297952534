import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';

export default function Header() {
    const [showpop, setShowpop] = useState(false)
    // useEffect(() => {
    //     setTimeout(() => {
    //         setShowpop(true)
    //     }, 1000)
    // }, [])
    const handleClickHidepop = () => {
        setShowpop(false);
    }
    const [show, setShow] = useState(false);
    const handleClickShow = () => {
        setShow(true);
    }
    const handleClickHide = () => {
        setShow(false);
    }
    const popoverTop = (
        <Popover id="popover-positioned-top" className='popover' title="Popover top">
            <strong><a href="https://help.onmaxyield.com/" target='_blank' style={{ fontSize: "12px" }}>Need <br /> Help ?</a></strong>
        </Popover>
    );
    return (
        <>

            <aside className="side-widget" style={show ? { display: "block" } : { display: "none" }}>
                <div className="inner">
                    {/* Logo Menu Mobile */}
                    <div className="logo d-flex justify-content-between"> <a href="/"><img src="assets/img/logo.png" alt="Image" /></a> <div style={{ fontSize: "20px" }} onClick={handleClickHide}>X</div></div>

                    <div className="hide-mobile">
                        <div className="or">
                            <h2 className="h2-baslik-anasayfa-ozel1"> Contact Information </h2>
                        </div>
                        <div className="bosluk2dt" />
                        <div className="iconsv"><i className="flaticon-call" /></div>
                        <address className="address">
                            <p><a href="#">+1 (234) 567 89 10</a>
                            </p><div className="bosluk2dt" />
                            <div className="iconsv"><i className="flaticon-email" /></div>
                            <a href="#">example@example.com</a>
                            <div className="bosluk2dt" />
                            <div className="iconsv"><i className="flaticon-location" /></div>
                            <a href="#">New Jersey, USA</a>
                            <div className="bosluk2dt" />
                            <div className="or">
                                <a href="#"><img src="assets/img/facebook1.png" alt="" /></a>
                                <a href="#"><img src="assets/img/instagram1.png" alt="" /></a>
                                <a href="#"><img src="assets/img/twitter1.png" alt="" /></a>
                                <a href="#"><img src="assets/img/google1.png" alt="" /></a>
                            </div>
                            <p />
                        </address>
                    </div>
                    <div className="show-mobile">
                        <div className="site-menu">
                            <ul className="menueffect">
                                <li><a href="/">Home</a></li>
                                <li><a href="#About">About Us</a></li>
                                <li><a href="#Advantage">Advantage</a></li>
                                <li><a href="/governance">Governance</a></li>
                                <li><a href="#Roadmap">Roadmap</a></li>
                                <li><a href="/transparency">Transparency</a></li>
                                <li><a href="https://europe.onmaxyield.com" target="_blank" className='btn btn-outline-primary mx-3 py-3 px-4' style={{ fontSize: "20px", textAlign: "center" }}>Start Now</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <nav className="navbar">

                <div className="container">
                    {/* Logo Menu Desktop */}
                    <div className="logo d-flex justify-content-between align-items-center"> <a href="/"><img src="assets/img/logo.png" alt="Image" /></a>
                    </div>
                    <div className="site-menu">
                        <ul className="menueffect">
                            <li><a href="/">Home</a></li>
                            <li><a href="#About">About Us</a></li>
                            <li><a href="#Advantage">Advantage</a></li>
                            <li><a href="/governance">Governance</a></li>
                            <li><a href="#Roadmap">Roadmap</a></li>
                             <li><a href="/transparency">Transparency</a></li>
                        </ul>
                    </div>

                    <div className="navbar-button"> <a href="https://europe.onmaxyield.com" target="_blank" style={{ fontSize: "20px", textAlign: "center" }}>Start Now</a> </div>
                    {/*<div className="navbar-button"> <a style={{ fontSize: "20px", textAlign: "center" }}> <i className='fa fa-sign-out'></i></a> </div>*/}

                    <div className="hamburger-menu d-lg-none d-sm-block" onClick={handleClickShow}> <span /> <span /> <span /> </div>
                </div>
            </nav>
            <OverlayTrigger trigger="click" placement="top" overlay={popoverTop} >
                <img width={90} height={90} src="assets/img/chat-boat.png" alt="" className='sticky' />
            </OverlayTrigger>

            {/* <div id="myModal" class="modal show" style={showpop ? { display: "block" } : { display: "none" }}>
                <div class="modal-content">
                    <span class="close" onClick={handleClickHidepop}>&times;</span>

                    <img src="assets/onmax-farming-going-live.jpg" title="Onmaxfarm"/>
                </div>

            </div> */}
            {/* <div id="myModal" class="modal show" style={showpop ? { display: "block" } : { display: "none" }}>
                <div class="modal-content">
                    <span class="close" onClick={handleClickHidepop}>&times;</span>

                    <h1> 🚀🚀🚀 Exciting Updates on Onmax Token Achievements! 🌐✨</h1>

                    <p> Great news, Onmax community! Our token has reached significant milestones and is now officially listed on two major platforms:</p>
                    <p>🦎 Coingecko Listing: Explore Onmax Token on Coingecko here. This listing amplifies our visibility and provides comprehensive information about Onmax Token's performance and market trends.</p>
                    <p>🛠️ DEXtools Listing: Check out our DEXtools listing here. DEXtools offers valuable insights into trading pairs and liquidity, enhancing transparency for our community.</p>
                    <p>Additionally, we're excited to share that the liquidity of Onmax Token is securely locked for the next six years on UNCX Network. This long-term commitment ensures stability and trust in our growing ecosystem.</p>
                    <p><a href="https://app.uncx.network/amm/quickswap-v1/pair/0x9375df24fd34d7a82d4bcdff2b763f4bf05ca2ab" target="_blank" style={{color: "#00c5ff"}}>https://app.uncx.network/amm/quickswap-v1/pair/0x9375df24fd34d7a82d4bcdff2b763f4bf05ca2ab</a></p>
                    <p>Thank you for being part of the Onmax journey! Stay tuned for more updates as we continue to soar to new heights.</p>

                </div>

            </div> */}
        </>
    )
}
