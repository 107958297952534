import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { OnmaxContext } from "../../OnmaxContext";

export default function Counter() {
  var { rate,settings } = useContext(OnmaxContext);
  const [dashd, setdashd] = useState(null);
  const [dashdsecond, setdashdsecond] = useState(null);
  const [geck, setgeck] = useState(null);

  const getDashd = async () => {
    // console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdashd(res.data.data);
      });
    await axios
      .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=onmax-2&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en")
      .then((res) => {
        console.log("aasdasda", res.data[0]);
        if(res.data.length){
            setgeck(res.data[0])
        }
      });

      await axios
      .post("https://onmaxyield.com:8443/api/onmax", {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdashdsecond(res.data.data);
      });
  };
  useEffect(() => {
    getDashd();
  }, []);
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="bosluk333" />
              <h2
                className="h2-baslik-anasayfa-ozel wow fadeInLeft"
                data-wow-delay="0.4s"
              >
                {" "}
                Creating the Future <strong>of Finance Today.</strong>
              </h2>
              <div className="bosluk333" />
              <div className="bosluk9" />
              <p
                className="paragraf wow fadeInLeft"
                data-wow-delay="0.5s"
                style={{ fontSize: "20px", textAlign: "left" }}
              >
                Onmax is a decentralised financial protocol which allows you to
                earn <br /> yields on your digital assets passively through
                staking and lending.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 wow fade">
              <div className="hero1 wow fadeInRight" data-wow-delay="0.6s">
                <div className="bosluk333" />
                <div className="bosluk333" />
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-12 fadeInLeft" data-wow-delay="0.7s">
                    <a
                      href=""
                      className="btn btn-outline-primary py-3 px-4"
                      style={{ fontSize: "20px", textAlign: "center" }}
                    >
                      Buy Onmax
                    </a>
                    <p
                      className="paragraf-info mt-5 text-left ml-2"
                      style={{ fontSize: "20px" }}
                    >
                      Build on the firm belief of a financial world that is
                      based on trust, security and anonymity enabled by smart
                      contracts. The goal is to enable financial sovereignty for
                      everyone.
                    </p>
                    <br />
                  </div>
                </div>
                <div className="bosluk1" />
              </div>
              <div className="boslukhm" />
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="box p-5 mb-5 rounded text-center">
                <h2
                  style={{ fontSize: "45px", color: "#fff", fontWeight: "700" }}
                >
                 $ {rate}
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Rate
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box p-5 mb-5 rounded text-center">
                <h2
                  style={{ fontSize: "45px", color: "#fff", fontWeight: "700" }}
                >
                  {settings !== null ? settings.t_holders : 0}
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Participate
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box p-5 mb-5 rounded text-center">
                <h2
                  style={{ fontSize: "45px", color: "#fff", fontWeight: "700" }}
                >
                  $ {Number(dashd !== null ? dashd.t_staking : 0) + Number(dashdsecond !== null ? dashdsecond.t_staking : 0) }
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Total Staking
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box p-5 mb-5 rounded text-center">
                <h2
                  style={{ fontSize: "45px", color: "#fff", fontWeight: "700" }}
                >
                 $ {Number(Number(dashd !== null ? Number(dashd.t_withdrawal) : 0) + Number(dashdsecond !== null ? Number(dashdsecond.t_withdrawal) : 0)).toFixed(2)}
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Total Withdrawal
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box p-5 mb-5 rounded text-center">
                <h2
                  style={{ fontSize: "45px", color: "#fff", fontWeight: "700" }}
                >
                 $ {geck !== null ? geck.total_volume : 0}
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  24 Hours Volume
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="box p-5 mb-5 rounded text-center">
                <h2
                  style={{ fontSize: "45px", color: "#fff", fontWeight: "700" }}
                >
                 $ {geck !== null ? geck.fully_diluted_valuation : 0}
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Total Diluted Valuation
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
