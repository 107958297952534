import React from 'react'

export default function AiNft() {
    return (
        <>
            <div>
                <section className="section">
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 wow fade">
                                <div className="hero1 wow fadeInRight" data-wow-delay="0.6s">
                                    <img src="./assets/img/ai.gif" alt="" srcset="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="bosluk333" />
                                <h2 className="h2-baslik-anasayfa-ozel wow fadeInLeft" data-wow-delay="0.4s"> <strong> Onmax AI NFT Soon
                                </strong> Connecting People </h2>
                                <div className="bosluk333" />
                                <div className="bosluk9" />
                                <p className="paragraf wow fadeInLeft" data-wow-delay="0.5s">
                                    Onmax  is the coming with biggest NFT community platform which gathers artists, developers, web3 entrepreneurs and ‘degens’ involved in blockchain space together with millions NFT fans.
                                </p>
                                <p className="paragraf wow fadeInLeft" data-wow-delay="0.5s">
                                    Our mission is to provide users with safety, security, social interaction, reliability and trustworthiness. Tapping the power of Blockchain, Onmax is creating a decentralized NFT utility platform that allows its users to borrow cryptocurrency by accepting NFTs as collateral.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
