import React from 'react'

export default function Governance() {
    return (
        <>
            <section className="section" id='Governance'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-lg-6  col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="bosluk333" />
                            <h2 className="h2-baslik-anasayfa-ozel wow fadeInLeft  text-lg-left text-sm-center " data-wow-delay="0.4s"> <strong> The governance module will consist of two smart contracts:
                            </strong></h2>
                            <div className="row">
                                <div className="col-sm-2 wow fadeInLeft" data-wow-delay="0.6s">
                                    <div className="iconleft"><i className="flaticon-transfer" /></div>
                                </div>
                                <div className="col-sm-10 wow fadeInLeft" data-wow-delay="0.7s">
                                    <h3 className="baslik-3s h-yazi-margin-kucuk1">Time Lock</h3><br />
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 wow fadeInLeft" data-wow-delay="0.8s">
                                    <div className="iconleft"><i className="flaticon-pay" /></div>
                                </div>
                                <div className="col-sm-10">
                                    <h3 className="baslik-3s h-yazi-margin-kucuk1">Onmax Security</h3><br />
                                    <br />
                                </div>
                            </div >
                            <div className="bosluk333" />
                            {/* <div className="row justify-content-center justify-content-lg-center"> */}
                            {/* </div> */}
                            <div className="bosluk333" />
                        </div>
                        {/*Banner Image Alanı*/}
                        <div className="col-lg-6  col-md-12 col-sm-12 wow fade">
                            <div className="hero1 wow fadeInRight" data-wow-delay="0.6s">
                                <img src="assets/img/governance.gif" alt="" />
                            </div>
                        </div>
                        <a className='btn btn-outline-primary my-3 py-3 px-4' style={{ fontSize: "20px" }}>Learn More</a>
                    </div>
                </div>
            </section>
        </>
    )
}
