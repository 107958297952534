import React from 'react'

export default function Cases() {
    return (
        <>
            <section className="section-2">
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className='box p-5 rounded'>
                                <h2>Affiliate </h2>
                                <p>Securely lend your assets and earn <br /> interests.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className='box p-5 rounded'>
                                <h2>Staking</h2>
                                <p>Validate transactions, enhance  the  network and get rewarded.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className='box p-5 rounded'>
                                <h2>Onmax Protocol</h2>
                                <p>NFT platform that allows its users to borrow cryptocurrency by accepting NFTs as collateral.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
