import React from 'react'
import Marquee from "react-fast-marquee";

export default function APY() {
    return (
        <>
            <section className='section'>
                <div className='container'>
                    <div className="markalar">
                        <div className='row justify-content-start'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h2 className="h2-baslik-hizmetler-21 wow fadeInUp text-left justify-content-start" data-wow-delay="0.5s">Find your favourite crypto
                                    <br />
                                    and let it work for you.</h2>
                                <br />
                                <p style={{ fontSize: "20px", textAlign: "left" }}>Onmax offers a wide variety of preselected and valuable digital assets that earn yields for you via smart contracts – hence your assets always belong to you.</p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="h-yazi-ortalama h-yazi-margin-kucuk-21 wow fadeInUp" data-wow-delay="0.6s" style={{ overflowX: "hidden" }}>
                            <Marquee>
                                <div className="marka">
                                    <img src="assets/img/1.png" width={140} alt="Marka 1" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/2.png" width={140} alt="Marka 2" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/3.png" width={140} alt="Marka 3" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/4.png" width={140} alt="Marka 4" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/5.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/6.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/7.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/8.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/9.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/10.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/11.png" width={140} alt="Marka 5" />
                                </div>
                                <div className="marka">
                                    <img src="assets/img/12.png" width={140} alt="Marka 5" />
                                </div>
                            </Marquee>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
