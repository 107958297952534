import logo from './logo.svg';
import './App.css';
import Index from './Commponets/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Governance from './Commponets/Governance';
import Transparency from './Commponets/Transparency';
function App() {
  return (
   <>
    {/* <Index/> */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/governance" element={<Governance />} />
        <Route path="/transparency" element={<Transparency />} />
      </Routes>
    </BrowserRouter>

   </>
  );
}

export default App;
