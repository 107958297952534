import React from 'react'
import Footer from './Comman/Footer'
import Header from './Comman/Header'

export default function Governance() {
    return (
        <>
            <Header />
            <main>
                <div className='container mt-5'>
                    <section className="info-alani pt-5" id='Roadmap'>
                        <div className='container mt-5'>
                            <div className='row justify-content-center'>
                                <div className='col-12 text-center'>
                                    <br />
                                    <h2 style={{ fontSize: "50px", color: "#00c5ff" }}>Governanace</h2>
                                    <br /><br /><br />
                                </div>
                                <div className='col-lg-9  col-sm-12'>
                                    <p className="post-kutu--yazi text-left">
                                        The time lock smart contract will become the owner of all smart contracts in the Onmax protocol. This will remove the “admin keys” from all contracts and make it only possible to perform any powerful action and upgrade via governance. The time lock ensures that if a proposal passes, the call data is executed after a certain time. The time lock can call any function on any contract through an arbitrary data call.
                                    </p><br />
                                    <p className='paragraf'>We will set up the main governance module on Binance Smart Chain (BEP20) , but as needed, additional deployments will be made on each EVM chain where Binance Smart Chain (BEP20)  operates.</p>
                                    <br />
                                    <p className='paragraf'>The Onmax Security module will be a fork of the already audited Compound Protocol smart contract, which is already battle-tested on the Binance Smart Chain main net(BEP20)  .</p><br />
                                    <p className='paragraf'>
                                        This battle-tested solution of time lock and governance will ensure that ONMAX will evolve into a community ($Onmax holder) owned protocol without any need to trust a third party.
                                    </p><br />
                                    <p className='paragraf'>
                                        Certainly, having the ability to withdraw funds even if a website is shut down or inaccessible is crucial for financial security. In many cases, when a website or platform closes, there are a few scenarios where users might still be able to access their funds:
                                    </p>
                                    <ul>
                                        <li>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}> Backup Withdrawal Methods:</h3> <p className='paragraf'>Some platforms allow users to set up backup withdrawal methods. This could include direct bank transfers, alternative payment systems like PayPal, or providing a wallet address for cryptocurrencies. Even if the website shuts down, users can access their funds through these alternative methods.</p>

                                        </li>


                                        <li>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Escrow Services or Third-Party Custodians:</h3>
                                            <p className='paragraf'> In certain cases, especially with financial institutions or larger platforms, an escrow service or third-party custodian might hold funds separately from the operating company. This ensures that even if the website ceases operations, users can reclaim their funds directly from this custodian.</p>
                                        </li>
                                        <li>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Legal Protections and Regulations:</h3>
                                            <p className='paragraf'> Depending on the regulatory framework, there might be legal provisions in place to protect users' funds. This could involve mandatory insurance, segregated accounts, or other measures to safeguard users' assets in the event of a platform closure.
                                            </p>
                                        </li>
                                        <li>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Communication and Notifications:</h3>
                                            <p className='paragraf'> Reputable platforms typically notify users well in advance of any closure or change in operations. This allows users to take necessary actions such as withdrawing funds or transferring them to a different account or platform.</p>
                                        </li>
                                    </ul>
                                <br />
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-9'>
                                <p className='paragraf'>However, it's important to note that not all platforms or situations may offer such protections. Users should be cautious and research the terms and conditions of any financial platform they use, as well as understand the risks involved in case of closure or shutdown. Creating backups, keeping track of communication from the platform, and understanding withdrawal procedures can all help safeguard funds in such situations.</p>
                            </div>
                        </div>
                </div>
            </section>
        </div >
            <Footer />
            </main >

        </>
    )
}
