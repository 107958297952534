import React from 'react'
import Footer from './Comman/Footer'
import Header from './Comman/Header'

export default function Transparency() {
    return (
        <>
            <Header />
            <main>
                <div className='container mt-5'>
                    <section className="info-alani pt-5" id='Roadmap'>
                        <div className='container mt-5'>
                            <div className='row justify-content-center'>
                                <div className='col-12 text-center'>
                                    <br />
                                    <br />
                                    <br />
                                    <h2 style={{ fontSize: "50px", color: "#00c5ff" }}>Onmax Transparency</h2>
                                    <br /><br /><br />
                                </div>
                                <div className='col-lg-9 col-sm-12'>
                                    <p className="post-kutu--yazi text-left">
                                        We believe that being open about how our Onmax Protocol witch is DeFi (Decentralized Finance) project operates is crucial for gaining trust. To stick to this promise, we share all the important contract details here. This gives everyone involved a clear understanding of how the project functions. By openly sharing information about our smart contracts, we make sure everyone is accountable and can make well-informed choices. This strengthens the values of decentralization and trust in the DeFi community.
                                    </p><br />

                                    <ul className='d-flex flex-wrap justify-content-start'>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}> Onmax Token:</h3> <p className='paragraf'><a href="https://bscscan.com/token/0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A" target='_blank'>
                                            0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A</a></p>
                                        </li>

                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}> Privatesale Onmax:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x8505bc5d63338d94ca3337c37620ea31eef0a081" target='_blank'>
                                            0x8505bc5d63338d94ca3337c37620ea31eef0a081</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Stretegicsale Onmax:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x267c682745a6ef94e8c8765926cbdf3ffe88600b" target='_blank'>
                                            0x267c682745a6ef94e8c8765926cbdf3ffe88600b</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Ecosystem Onmax:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0xaed21d1d8852c9eacba8d05e1c7432adc1a5215a" target='_blank'>
                                            0xaed21d1d8852c9eacba8d05e1c7432adc1a5215a</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>DexLiquidity Onmax:</h3> <p className='paragraf'>
                                                <a href="https://bscscan.com/address/0x1c2ef9d01dbdc6a8e621becadc4a53f119b479eb" target='_blank'>
                                                0x1c2ef9d01dbdc6a8e621becadc4a53f119b479eb</a></p>
                                        </li>

                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Asia Affilate:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x4B2e283d4F16f5e8959bf78d6C49976d94d6369E" target='_blank'>
                                            0x4B2e283d4F16f5e8959bf78d6C49976d94d6369E</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Onmax Defi:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x2e32b568e82e80ec54ed998cadd92d4d88acc378" target='_blank'>
                                            0x2e32b568e82e80ec54ed998cadd92d4d88acc378</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Onmax Community Reward (CCR):</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x0922e230c8af797132b69031cac79105c78169fc" target='_blank'>
                                            0x0922e230c8af797132b69031cac79105c78169fc</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Onmax Custody:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0xff40693bb14bc5dcb84d608101e8963b13e2aaf8" target='_blank'>
                                            0xff40693bb14bc5dcb84d608101e8963b13e2aaf8</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Onmax Protocol:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x384cc4ced095b2fbb30ce136a857512297c226f2" target='_blank'>
                                            0x384cc4ced095b2fbb30ce136a857512297c226f2</a></p>
                                        </li>
                                        <li className='px-4 text-break'>
                                            <h3 style={{ fontSize: "20px", color: "#00c5ff" }}>Europe Affiliate:</h3> <p className='paragraf'><a href="https://bscscan.com/address/0x7841084948728D3060081Cf5D65dFE4B6A8E0C44" target='_blank'>
                                            0x384cc4ced095b2fbb30ce136a857512297c226f2</a></p>
                                        </li>
                                    </ul>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
                <Footer />
            </main >

        </>
    )
}
