import React, { useState } from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import Governance from './Governance'
import Cases from './Cases'
import Counter from './Counter'
import Documents from './Documents'
import TokenCharts from './TokenCharts'
import Partners from './Partners'
import Consultation from './Consultation'
import Blog from './Blog'
import Future from './Future'
import AiNft from './AiNft'
import Roadmaap from './Roadmaap'
import About from './About'
import Advantage from './Advantage'
import APY from './APY'
import Burning from './Burning'
import Timer from './Timer'
import { useEffect } from 'react'

export default function Index() {
    return (
        <>
            <Header />
            <Hero />
            <Cases />
            {/* <Timer /> */}
            <About />
            <Advantage />
            <APY />
            <Future />
            <Counter />
            <AiNft />
            <Burning />
            <Roadmaap />
            <Governance />
            <Partners />
            <Footer />
            
        </>
    )
}
