import React from 'react'

export default function Future() {
    return (
        <>
            <section className="section">
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="bosluk333" />
                            <h2 className="h2-baslik-anasayfa-ozel wow fadeInLeft" data-wow-delay="0.4s"> <strong> Staking and Affiliate  Onmax
                            </strong> With 3 Easy Steps </h2>
                            <div className="bosluk333" />
                            <div className="bosluk9" />
                            <p className="paragraf wow fadeInLeft" data-wow-delay="0.5s">
                                Select your digital asset in Staking and onmax protocol put it to work. Securely earning you yields and growing your portfolio.
                            </p>

                        </div>
                        {/*Banner Image Alanı*/}
                        <div className="col-lg-6 col-md-12 col-sm-12 wow fade">
                            <div className="hero1 wow fadeInRight" data-wow-delay="0.6s">
                                <div className="bosluk333" />
                                <div className="bosluk333" />
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                                        <div className="iconleft"><img src="./assets/img/icons/wallte.png" alt="" srcset="" /></div>
                                    </div>
                                    <div className="col-lg-10 col-sm-12 wow fadeInLeft" data-wow-delay="0.7s">
                                        <h3 className="baslik-3s h-yazi-margin-kucuk1">CONNECT WALLET</h3><br />
                                        <p className="paragraf-info">Connect your wallet (metamask, trezor, ledger..) via your browser..</p><br />
                                    </div>
                                </div>
                                <div className="bosluk1" />
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                                        <div className="iconleft"><img src="./assets/img/icons/start-affiliate-and-staking.png" alt="" srcset="" /></div>
                                    </div>
                                    <div className="col-lg-10 col-sm-12">
                                        <h3 className="baslik-3s h-yazi-margin-kucuk1">Start Affiliate and Staking </h3><br />
                                        <p className="paragraf-info">Select your desired asset in staking  and earn from  Affiliate method.</p><br />
                                    </div>
                                </div>
                                <div className="bosluk1" />
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                                        <div className="iconleft"><img src="./assets/img/icons/grow-passivly.png" alt="" srcset="" /></div>
                                    </div>
                                    <div className="col-lg-10 col-sm-12 wow fadeInLeft" data-wow-delay="1s">
                                        <h3 className="baslik-3s h-yazi-margin-kucuk1">GROW PASSIVELY</h3><br />
                                        <p className="paragraf-info">Smart contracts will do all the technical work automatically for you.</p><br />
                                    </div>
                                </div>
                            </div>
                            <div className="boslukhm" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section mt-0">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="season_tabs">
                                <div className="season_tab">
                                    <div className="season_content">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-5 col-sm-12">
                                                    {/* <video muted
                                                        autoPlay
                                                        loop id="myVideo"><source src="./assets/img/trusted.mp4" type="video/mp4" /></video> */}
                                                    <img src="assets/img/trusted.png" alt="" />
                                                </div>
                                                <div className="col-lg-7 col-sm-12 d-flex align-items-center">
                                                    <div>
                                                        <h2 style={{ fontSize: "40px" }}>Trusted Crypto Platform</h2>
                                                        <p className="listtext">
                                                            Onmax protocol is based in the following 4 principles
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="paketler4 wow fadeInLeft" data-wow-delay="0.5s">
                                <div className="paketler4__on paketler4__on--onyazi1">
                                    <div className="paketler4__icerik">
                                        <div className="icon"><img src="./assets/img/incentives.png" alt="" srcset="" /></div>
                                        <h3 className="baslik-3 h-yazi-margin-kucuk">SECURE</h3>
                                        <br />
                                        <p className='listtext text-dark text-center'>Economic Incentives and Mechanisms.</p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Quality 2*/}
                        <div className="col-lg-4">
                            <div className="paketler4 wow fadeInLeft" data-wow-delay="0.6s">
                                <div className="paketler4__on paketler4__on--onyazi2">
                                    <div className="paketler4__icerik">
                                        <div className="icon"><img src="./assets/img/simple.png" alt="" srcset="" /></div>
                                        <h3 className="baslik-3 h-yazi-margin-kucuk">SIMPLE</h3>
                                        <br />
                                        <p className='listtext text-dark text-center'>Easy to use from any device.</p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center my-5">
                        {/*Quality 3*/}
                        <div className="col-lg-4">
                            <div className="paketler4 wow fadeInRight" data-wow-delay="0.7s">
                                <div className="paketler4__on paketler4__on--onyazi3">
                                    <div className="paketler4__icerik">
                                        <div className="icon"><img src="./assets/img/anonymous.png" alt="" srcset="" /></div>
                                        <h3 className="baslik-3 h-yazi-margin-kucuk">ANONYMOUS</h3>
                                        <br />
                                        <p className='listtext text-dark text-center'>Simply connect your Web3 wallet.</p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Quality 4*/}
                        <div className="col-lg-4">
                            <div className="paketler4 wow fadeInRight" data-wow-delay="0.8s">
                                <div className="paketler4__on paketler4__on--onyazi4">
                                    <div className="paketler4__icerik">
                                        <div className="icon"><img src="./assets/img/desentrllize.png" alt="" srcset="" /></div>
                                        <h3 className="baslik-3 h-yazi-margin-kucuk">DECENTRALIZED</h3>
                                        <p className='listtext text-dark text'>Onmax Platform is Available For Your Assets Without Restrictions Or Limitations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="section mt-0">
                <div className='container'>
                    
                </div>
            </section> */}
        </>
    )
}
