import React from 'react'

export default function Advantage() {
    return (
        <>
            <section className="section" id='Advantage'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6  col-md-12 col-sm-12 wow fade">
                            <div className="hero1 wow fadeInRight" data-wow-delay="0.6s">
                                <img src="assets/img/advantage.gif" alt="" />
                            </div>
                            <div className="boslukhm" />
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="bosluk333" />
                            <h2 className="h2-baslik-anasayfa-ozel wow fadeInLeft" data-wow-delay="0.4s"> <strong> Advantage of Onmax
                            </strong></h2>
                            <div className="bosluk333" />
                            <div className="bosluk9" />
                            {/* <p className="paragraf wow fadeInLeft" data-wow-delay="0.5s">
                            From day one we designed and built a modern crypto template for beginners and experts alike.
                            Make easy deposits and withdrawals, measure the performance of your portfolio, and track all your
                            cryptocurrencies in one place.
                        </p> */}
                            <div className="bosluk333" />
                            <div className="bosluk333" />
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 wow fadeInLeft" data-wow-delay="0.6s">
                                    <div className="iconleft"><img src="./assets/img/icons/swift-transactions.png" alt="" srcset="" /></div>
                                </div>
                                <div className="col-lg-10 col-sm-12 wow fadeInLeft" data-wow-delay="0.7s">
                                    <h3 className="baslik-3s h-yazi-margin-kucuk1">01 Swift Transactions:</h3><br />
                                    <p className="paragraf-info">Binance Smart Chain (BEP20)  serves as a Layer 1 scaling solution, offering rapid and cost-effective transactions, in stark contrast to Ethereum. This ensures that users can engage in Onmax  staking without concerns about exorbitant gas fees or sluggish transaction processing times.</p><br />
                                </div>
                            </div>
                            <div className="bosluk1" />
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 wow fadeInLeft" data-wow-delay="0.8s">
                                    <div className="iconleft"><img src="./assets/img/icons/security-and-decentralization.png" alt="" srcset="" /></div>
                                </div>
                                <div className="col-lg-10 col-sm-12">
                                    <h3 className="baslik-3s h-yazi-margin-kucuk1">02 Security and Decentralization:</h3><br />
                                    <p className="paragraf-info">Onmax Protocol Affiliate, Staking and AI NFT platform is constructed on the robust foundation of Binance Smart Chain (BEP20), guaranteeing a secure and decentralized environment for staking activities. Users exercise complete authority over their assets, and the platform operates under the safeguard of smart contracts that facilitate equitable and transparent rewards distribution.</p><br />
                                </div>
                            </div>
                            <div className="bosluk1" />
                            <div className="row">
                                <div className="col-lg-2 col-sm-12 wow fadeInLeft" data-wow-delay="0.9s">
                                    <div className="iconleft"><img src="./assets/img/icons/diversification-of-portfolios.png" alt="" srcset="" /></div>
                                </div>
                                <div className="col-lg-10 col-sm-12 wow fadeInLeft" data-wow-delay="1s">
                                    <h3 className="baslik-3s h-yazi-margin-kucuk1">03 Diversification of Portfolios:</h3><br />
                                    <p className="paragraf-info">Staking not only enhances the value of NFT holdings but also permits users to diversify their portfolios by earning rewards in the form of cryptocurrency or tokens. This supplementary passive income stream can act as a hedge against the volatility of the cryptocurrency market.</p><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
