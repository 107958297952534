import React from 'react'

export default function Partners() {
    return (
        <>
            <section className='section mt-5'>
                <div className='container'>
                    <div className="markalar">
                        <h2 className="h2-baslik-hizmetler-21 wow fadeInUp" data-wow-delay="0.5s">FEATURED IN:</h2>
                        <br />
                        <div className="h-yazi-ortalama h-yazi-margin-kucuk-21 wow fadeInUp" data-wow-delay="0.6s">
                            <img src="assets/img/icons/beatmarket-1.png" alt="Marka 1" className="marka" />
                            <img src="assets/img/icons/coingecko-1.png" alt="Marka 2" className="marka" />
                            <img src="assets/img/icons/coinjournal-1.png" alt="Marka 3" className="marka" />
                            <img src="assets/img/icons/coinmarketcap-1.png" alt="Marka 4" className="marka" />
                        </div>
                        <div className="h-yazi-ortalama h-yazi-margin-kucuk-21 wow fadeInUp" data-wow-delay="0.6s">
                            <img src="assets/img/icons/dext-tool-1.png" alt="Marka 5" className="marka" />
                            <img src="assets/img/icons/metamask-1.png" alt="Marka 1" className="marka" />
                            <img src="assets/img/icons/pancakeswap-1.png" alt="Marka 2" className="marka" />
                            <img src="assets/img/icons/bsclab.png" alt="Marka 3" className="marka" />
                        </div>
                        <div className="h-yazi-ortalama h-yazi-margin-kucuk-21 wow fadeInUp" data-wow-delay="0.6s">
                            <img src="assets/img/icons/bscscan.png" alt="Marka 4" className="marka" />
                            <img src="assets/img/icons/the-telegraph-online-1.png" alt="Marka 5" className="marka" />
                            <img src="assets/img/icons/trustwallet-1.png" alt="Marka 1" className="marka" />
                            <img src="assets/img/icons/yahoo-finance-1.png" alt="Marka 2" className="marka" />
                        </div>
                        <div className="h-yazi-ortalama h-yazi-margin-kucuk-21 wow fadeInUp" data-wow-delay="0.6s">
                            <img src="assets/img/icons/zengo-1.png" alt="Marka 3" className="marka" />
                            <img src="assets/img/icons/fox-28.png" alt="Marka 3" className="marka" />
                            <img src="assets/img/icons/market-watch.png" alt="Marka 3" className="marka" />
                            <img src="assets/img/icons/trustwallet-1.png" alt="Marka 1" className="marka" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
