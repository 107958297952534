import React from 'react'

export default function Burning() {
    return (
        <>
            <section className="section">
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                            <div className='box p-5 rounded'>
                                <h2 style={{ fontSize: "45px", color: "#fff" }} className='text-center'>Onmax Burning </h2>
                                <br />
                                <p style={{ fontSize: "18px", position: "relative", zIndex: "999" }} className='text-center my-5'>Onmax protocol Burning is often conducted transparently and recorded on the blockchain for accountability and to assure users of the decreased supply. It's a strategic move employed by projects to manage supply dynamics, influence market perception, and potentially impact the token's value within the market ecosystem.</p>
                                
                                {/* <img src="./assets/img/fire.gif" className='bg-img-1' alt="" srcset="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
