import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';

export default function Footer() {
    const popoverTop = (
        <Popover id="popover-positioned-top" className='popover' title="Popover top">
            <strong><a href="#" style={{ fontSize: "12px" }}>Need Help ?</a></strong>
        </Popover>
    );
    return (
        <>
            <footer className="footer">
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <div className="tablo--1-ve-5 wow fadeInUp" data-wow-delay="0.3s">
                                <a href="#">
                                    <img src="assets/img/logo-footer.png" width={150} alt="Image" />
                                </a>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-sm-6 text-center'>
                                <p className='text-center text-break' style={{ fontSize: "15px" }}>Onmax Protocol is fully decentralized and independent platform available
                                    for your Onmax asset without restrictions or limitations.</p>
                                <br /><br /><br />
                                <div className="footer-social d-flex justify-content-around">
                                    <a href="https://twitter.com/onmaxprotocol" target='_blank'><img src="./assets/img/twt.png" alt="" srcset="" /></a>
                                    <a href="https://www.facebook.com/people/OnmaxProtocol/61555490522388/" target='_blank'><img src="./assets/img/fb.png" alt="" srcset="" /></a>
                                    <a href="https://www.instagram.com/onmaxprotocol/" target='_blank'><img src="./assets/img/insta.png" alt="" srcset="" /></a>
                                    <a href="https://t.me/+Q-IBiQwK51JmNDg8" target='_blank'><img src="./assets/img/tele.png" alt="" srcset="" /></a>
                                    <a href="https://www.reddit.com/user/onmaxprotocol" target='_blank'><img src="./assets/img/reddit.png" alt="" srcset="" /></a>
                                    {/* <a href="https://bitcointalk.org/index.php?action=profile" target='_blank'><img src="./assets/img/bitcointalk.png" alt="" srcset="" /></a> */}
                                    <a href="https://www.youtube.com/@onmaxprotocol" target='_blank'><img src="./assets/img/youtube.png" alt="" srcset="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="wow fadeInUpd d-flex" data-wow-delay="0.9s">
                        <div className="footer__copyright text-center">
                            Copyright   © 2023 - Onmax - All Rights Reserved.
                        </div>

                    </div>
                </div>

            </footer>
        </>
    )
}
