import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import OnmaxContext from './OnmaxContext';
import { Web3ReactProvider } from '@web3-react/core'
import web3 from 'web3';

const getLibrary = (provider) => {
  return new web3(provider)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Web3ReactProvider getLibrary={getLibrary} >
    <OnmaxContext />
  </Web3ReactProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
