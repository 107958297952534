import React from 'react'

export default function Roadmaap() {
    return (
        <>
            <section className="section-3" id='Roadmap'>
                <div className='container'>
                    <main id="main" className="section-margin section-padding">
                        <header>
                            <h1 style={{ fontSize: "45px", color: "#fff" }}>Roadmap</h1>
                        </header>
                        <div style={{ minHeight: '250px' }}>
                            <div style={{ position: 'relative' }}>
                                <div className="timeline">
                                    <div className="card-item-pcblue"><span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: '2.5rem' }}> <img src="assets/img/roadmap-1.png" className='roadmap-img' alt="" /> &nbsp;</span>
                                        <div className="subcard"><strong>Q3 2023</strong>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Market Analysis and Evaluation</li>
                                                <li>Expansion Beyond Core Features</li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="card-item-yellow"><span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: '2.5rem' }}><img src="assets/img/roadmap-2.png" className='roadmap-img' alt="" /> &nbsp;</span>
                                        <div className="subcard"><strong>Q1 2024</strong>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Affiliate and Staking Initiative Unveiling and Platform Introduction</li>
                                                <li>Embrace of Decentralized Growth</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-item-orange"><span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: '2.5rem' }}><img src="assets/img/roadmap-3.png" className='roadmap-img' alt="" /> &nbsp;</span>
                                        <div className="subcard"><strong>Q2 2024</strong>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Improving Platform Features and Enhancing User Interaction</li>
                                                <li>Collaboration with Another Blockchain and Service Integration</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-item-green"><span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: '2.5rem' }}><img src="assets/img/roadmap-4.png" alt="" className='roadmap-img' /> &nbsp;</span>
                                        <div className="subcard"><strong>Q3 2024</strong>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Public Relations Campaign and Community Influencers for Outreach</li>
                                                <li>Collaborative Development and Community-Led Governance</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="card-item-green"><span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: '2.5rem' }}><i aria-hidden="true" className="fa fas fa-circle fa-stack-2x" /> <i aria-hidden="true" className="fa fa-house fa-stack-1x fa-inverse" /> &nbsp;</span>
                                        <div className="subcard"><strong>Q4 2024</strong>
                                            <br />
                                            <br />
                                            Coutinuous Innovation & Growth
                                        </div>
                                    </div> */}
                                </div>
                                <div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </section>
        </>
    )
}
